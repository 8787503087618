import { multiGrantLog as log } from '../../../components/CommunicationLog/pages/log';
import nextSteps from '../../../components/CommunicationLog/pages/nextSteps';
import supportingAttachments from '../../../components/CommunicationLog/pages/supportingAttachments';

const pages = [
  log,
  supportingAttachments,
  nextSteps,
];
export default pages;
