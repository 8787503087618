/* eslint-disable import/prefer-default-export */
import {
  communicationDateFilter,
} from '../../components/filter/communicationLogFilters';

const DASHBOARD_FILTER_CONFIG = [
  communicationDateFilter,
];

export { DASHBOARD_FILTER_CONFIG };
